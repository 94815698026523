import {Grid, Typography, Fab, Backdrop} from '@mui/material'
import {useAtom} from "jotai"
import {
  queryResponse
} from "../../jotai/atom"
import TotalResult from "./TotalResult";
import DetailResult from "./DetailResult";
import React from "react";

const TestQueryResult = () => {
  const [testResult] = useAtom(queryResponse)

  return testResult == null ? (<></>) : testResult.result == null ? (<></>) : (
    <Grid container>
      <Grid lg={16} sm={16} item={true}>
        <Typography variant="h6" ml={3}>検証結果</Typography>
        <TotalResult total_count={testResult.result.all_result.length} total={testResult.result.total}/>
        <Typography variant="h6" ml={3} mt={2}>詳細</Typography>
        <DetailResult all_result={testResult.result.all_result} />
      </Grid>
    </Grid>
  )
}

export default TestQueryResult