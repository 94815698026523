import './App.css';
import {createTheme, ThemeProvider} from '@mui/material/styles';

import Amplify, {Auth} from 'aws-amplify';

import Main from './components/Main';
import {useEffect, useState} from "react";

Amplify.configure({
  Auth: {
    region: 'ap-northeast-1',
    userPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USERPOOL_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_COGNITO_DOMAIN,
      scope: ['openid'],
      redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT_URL,
      redirectSignOut: process.env.REACT_APP_COGNITO_REDIRECT_URL,
      responseType: 'code'
    }
  },
  API: {
    endpoints: [
      {
        name: 'DefaultAPI',
        endpoint: process.env.REACT_APP_API_URL
      }
    ]
  }
})

const theme = createTheme({
  palette: {
    primary: {
      main: '#009ade',
    },
    secondary: {
      main: '#009ade',
    },
  },
});

const App = () => {
  const [user, setUser] = useState<any|undefined>(undefined)

  useEffect(() => {
    (async () => {
      Auth.currentAuthenticatedUser().then(
        u => {
          setUser(u)
        }
      ).catch(() => Auth.federatedSignIn().then(u => setUser(u)))
    })();
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Main user={user} />
    </ThemeProvider>
  );
}

export default App;
