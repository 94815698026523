import {Box, CircularProgress} from "@mui/material";


const Loader = () => {
  return (
    <Box
      sx={{ padding: 1 }}
      display="flex"
      justifyContent="space-evenly"
      alignItems="center"
    >
      <CircularProgress color="inherit" size={30}/>
    </Box>
  )
}

export default Loader