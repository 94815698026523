import React from 'react'
import {
  Box,
  Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel,
  Paper
} from '@mui/material'
import { visuallyHidden } from '@mui/utils'

import {OneSensorInfo} from "../../types/types"
import {useAtom} from "jotai";
import {newAlertSettingsOfTargetGroup} from "../../jotai/atom";

interface DetailResultProps {
  all_result: OneSensorInfo[]
}

// https://mui.com/material-ui/react-table/#sorting-amp-selecting

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

type Order = 'asc' | 'desc'

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

interface HeadCell {
  disablePadding: boolean
  id: keyof OneSensorInfo
  label: string
  numeric: boolean
}

const headCells: readonly HeadCell[] = [
  {
    id: 'serial_no',
    numeric: false,
    disablePadding: false,
    label: 'シリアル',
  },
  {
    id: 'device_id',
    numeric: false,
    disablePadding: false,
    label: 'デバイスID',
  },
  {
    id: 'customer_code',
    numeric: false,
    disablePadding: false,
    label: '顧客コード',
  },
  {
    id: 'customer_name',
    numeric: false,
    disablePadding: false,
    label: '顧客名',
  },
  {
    id: 'customer_address',
    numeric: false,
    disablePadding: false,
    label: '顧客住所',
  },
  {
    id: 'min_voltage',
    numeric: true,
    disablePadding: false,
    label: '最低電圧[V]',
  },
  {
    id: 'missing_ratio',
    numeric: true,
    disablePadding: false,
    label: '欠損率[%]',
  },
  {
    id: 'error_ratio',
    numeric: true,
    disablePadding: false,
    label: 'エラー率[%]',
  },
  {
    id: 'plateau_count_ratio',
    numeric: true,
    disablePadding: false,
    label: '不動検出率[%]',
  },
]

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof OneSensorInfo) => void
  order: Order
  orderBy: string
  rowCount: number
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } =
    props
  const createSortHandler =
    (property: keyof OneSensorInfo) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const toDisplayPercentage = (numAsString: string) => {
  return numAsString === '' ? '' : `${Number(parseFloat(numAsString) * 100).toFixed(2)}`
}

const DetailResult = (props: DetailResultProps) => {
  const [newSettings] = useAtom(newAlertSettingsOfTargetGroup)

  const rows = props.all_result

  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof OneSensorInfo>('serial_no')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof OneSensorInfo,
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

  return (
    <Box sx={{ width: '95%', padding: 3 }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 600 }}
            aria-labelledby="tableTitle"
            size={'small'}
            stickyHeader
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {rows.sort(getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`

                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.serial_no}
                    >
                      <TableCell
                        id={labelId}
                      >
                        {row.serial_no}
                      </TableCell>
                      <TableCell align="left">{row.device_id}</TableCell>
                      <TableCell align="left">{row.customer_code}</TableCell>
                      <TableCell align="left">{row.customer_name}</TableCell>
                      <TableCell align="left">{row.customer_address}</TableCell>
                      <TableCell
                        align="right"
                        sx={
                          newSettings.min_voltage_threshold == null ?
                            {} :
                            parseFloat(row.min_voltage) <= newSettings.min_voltage_threshold ?
                              {backgroundColor: "#ff000077"} :
                              {}
                        }
                      >{row.min_voltage}</TableCell>
                      <TableCell
                        align="right"
                        sx={
                          newSettings.missing_ratio_threshold == null ?
                            {} :
                            parseFloat(row.missing_ratio) >= newSettings.missing_ratio_threshold ?
                              {backgroundColor: "#ff000077"} :
                              {}
                        }
                      >{toDisplayPercentage(row.missing_ratio)}</TableCell>
                      <TableCell
                        align="right"
                        sx={
                          newSettings.error_ratio_threshold == null ?
                            {} :
                            parseFloat(row.error_ratio) >= newSettings.error_ratio_threshold ?
                              {backgroundColor: "#ff000077"} :
                              {}
                        }
                      >{toDisplayPercentage(row.error_ratio)}</TableCell>
                      <TableCell
                        align="right"
                        sx={
                          newSettings.plateau_ratio_threshold == null ?
                            {} :
                            parseFloat(row.plateau_count_ratio) >= newSettings.plateau_ratio_threshold ?
                              {backgroundColor: "#ff000077"} :
                              {}
                        }
                      >{toDisplayPercentage(row.plateau_count_ratio)}</TableCell>
                    </TableRow>
                  )
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 33 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 15, 30, 50]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  )
}

export default DetailResult