import {atom} from "jotai";

import {AlertSettingValues, Group, QueryResponse} from "../types/types"
import {callPostApi} from "../api/api";

export const targetGroup = atom<Group|null>(null)
export const allGroupList = atom(
  async () => {
    return callPostApi('/get_group_list')
  }
)

export const presentAlertSettingsOfTargetGroup = atom(
  async (get) => {
    const group = get(targetGroup)
    if (group == null) {
      return undefined
    }
    const companyId = group.company_id
    const groupId = group.group_id
    const response = await callPostApi('/read_alert_condition', {
      company_id: companyId,
      group_id: groupId
    })
    return response as AlertSettingValues
  },
)

export const newAlertSettingsOfTargetGroup = atom<AlertSettingValues>(
  {
    period: 'one_week',
    min_voltage_threshold: 2.0,
    missing_ratio_threshold: 0.65,
    error_ratio_threshold: 0.5,
    plateau_ratio_threshold: 0.5,
  }
)

export const isPresentAlertSettingsCopiable = atom(
  (get) => {
    const presentAlertSettings = get(presentAlertSettingsOfTargetGroup)
    if (presentAlertSettings == null) {
      return false
    }
    return Object.values(presentAlertSettings).filter(value => value != null).length !== 0;
  }
)

export const isNewAlertSettingsValid = atom(
  (get) => {
    const newAlertSettings = get(newAlertSettingsOfTargetGroup)
    if (newAlertSettings == null) {
      return false
    }

    // 期間および最低でも1項目が設定されている必要がある
    if (Object.values(newAlertSettings).filter(value => value != null).length <= 1) {
      return false
    }
    return newAlertSettings.period != null
  }
)

export const isQuerying = atom(false)

export const queryResponse = atom<QueryResponse|undefined>(undefined)

export const isSaving = atom(false)

export const isError = atom(false)

export const errorMessage = atom('')