import {Grid} from '@mui/material'

import Header from './header/Header';
import Content from './content/Content';
import Sidebar from './sidebar/Sidebar';

const Main = (user: any) => {
  if (user == null) {
    return (
      <></>
    )
  }

  return (
    <Grid sx={{ display: 'flex' }}>
      <Header/>
      <Sidebar/>
      <Content/>
    </Grid>
  )
}

export default Main;
