import {Card, CardContent, Typography} from "@mui/material";

interface BansCardProps {
  title: string
  displayNumber: string
  unit: string
}

// BANs = big ass numbers (https://towardsdatascience.com/on-the-power-of-the-ban-aa783510f3a1)
const BansCard = (props: BansCardProps) => {
  const {title, displayNumber, unit} = props
  return (
    <Card sx={{ minWidth: 200 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.primary" gutterBottom align="center">{title}</Typography>
        <Typography variant="h4" component="div" align="center">{`${displayNumber} ${unit}`}</Typography>
      </CardContent>
    </Card>
  )
}

export default BansCard