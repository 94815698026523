import {Suspense} from "react";

import {
  Drawer,
  Toolbar,
  List, ListItem, Box
} from '@mui/material';

import {useAtom} from "jotai";

import GroupListDropdown from "./GroupListDropdown";
import AlertSettings from "./AlertSettings";
import Loader from "../ui/Loader";
import {targetGroup} from "../../jotai/atom";
import ControlButtons from "./ControlButtons";

const drawerWidth = 450;

const Sidebar = () => {
  const [group] = useAtom(targetGroup)

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' }
      }}
    >
      <Toolbar sx={{
        marginTop: 3
      }}/>
      <Suspense fallback={<Loader />}>
        <GroupListDropdown />
      </Suspense>
      {
         group ? (
          <List>
            <ListItem>会社名: {group.company_name}</ListItem>
            <ListItem>グループ名: {group.group_name}</ListItem>
          </List>
         ) : (
           <List>
             <ListItem>グループが選択されていません</ListItem>
           </List>
         )
      }
      <Box>
        <Suspense fallback={<></>}>
          <ControlButtons />
        </Suspense>
        <Suspense fallback={<Loader />}>
          <AlertSettings />
        </Suspense>
      </Box>
    </Drawer>
  )
}

export default Sidebar