import {Grid, Toolbar, Backdrop, Typography} from '@mui/material'
import Loader from "../ui/Loader";
import {isQuerying, isSaving, isError, errorMessage} from "../../jotai/atom";
import {useAtom} from "jotai";
import React from "react";
import TestQueryResult from "./TestQueryResult";

const Content = () => {
  const [isTestingConditions] = useAtom(isQuerying)
  const [isSavingAlertCondition] = useAtom(isSaving)
  const [isErrorOccurred] = useAtom(isError)
  const [errMessage] = useAtom(errorMessage)

  return (
    <Grid container>
      <Grid lg={16} sm={16} item={true}>
        <Toolbar sx={{
          marginTop: 3
        }}/>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isTestingConditions}
        >
          <Loader />
          <Typography variant="caption">検証クエリを実行中…（10分以上終わらない場合は開発者にお知らせください。）</Typography>
        </Backdrop>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isSavingAlertCondition}
        >
          <Loader />
          <Typography variant="caption">アラート設定を更新中…</Typography>
        </Backdrop>
        <Backdrop
          sx={{ color: '#f99', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isErrorOccurred}
        >
          <Typography variant="h6">{errMessage}</Typography>
        </Backdrop>
        <TestQueryResult />
      </Grid>
    </Grid>
  )
}

export default Content