import {
  newAlertSettingsOfTargetGroup,
  presentAlertSettingsOfTargetGroup,
} from "../../jotai/atom";
import {
  FormControl, MenuItem, Select, SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import NumberFormat from "react-number-format"
import {useAtom} from "jotai";
import {Period} from "../../types/types";

const periodDefinitions = [
  {value: 'one_week', displayName: '1週間'},
  {value: 'two_weeks', displayName: '2週間'},
  {value: 'one_month', displayName: '1ヶ月'},
]

const periodToDisplayPeriod = (period: string | undefined) => {
  if (period == null) return '未設定'
  const targetPeriods = periodDefinitions.filter(periodDefinition => periodDefinition.value === period);
  if (targetPeriods.length === 0) {
    throw new Error(`対象期間の指定 ${period} は定義されていないため、処理できません。`);
  }
  return `過去${targetPeriods[0].displayName}`
}

const AlertSettings = () => {
  const [presentAlertSettings] = useAtom(presentAlertSettingsOfTargetGroup)
  const [newAlertSettings, setNewAlertSettings] = useAtom(newAlertSettingsOfTargetGroup)

  const handlePeriodChange = (event: SelectChangeEvent) => {
    setNewAlertSettings({...newAlertSettings, period: event.target.value as Period})
    console.log(newAlertSettings)
  }
  const handleMinVoltageChange = (newMinVoltageThreshold: number | undefined) => {
    setNewAlertSettings({...newAlertSettings, min_voltage_threshold: newMinVoltageThreshold})
  }
  const handleMissingRatioChange = (newMissingRatioThreshold: number | undefined) => {
    setNewAlertSettings({...newAlertSettings, missing_ratio_threshold: newMissingRatioThreshold})
  }
  const handleErrorRatioChange = (newErrorRatioThreshold: number | undefined) => {
    setNewAlertSettings({...newAlertSettings, error_ratio_threshold: newErrorRatioThreshold})
  }
  const handlePlateauRatioChange = (newPlateauRatioThreshold: number | undefined) => {
    setNewAlertSettings({...newAlertSettings, plateau_ratio_threshold: newPlateauRatioThreshold})
  }

  if (presentAlertSettings == null) {
    return <></>
  }

  return (
    <Table sx={{ padding: 1 }}>
      <TableHead>
        <TableRow key='header'>
          <TableCell>項目名</TableCell>
          <TableCell>現在の値</TableCell>
          <TableCell>設定値</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow key='period'>
          <TableCell>集計期間</TableCell>
          <TableCell>{`${periodToDisplayPeriod(presentAlertSettings?.period)}`}</TableCell>
          <TableCell>
            <FormControl fullWidth>
              <Select
                labelId="period-label"
                id="period-selection"
                value={newAlertSettings.period}
                onChange={handlePeriodChange}
              >
                {periodDefinitions.map((periodDefinition) => (
                  <MenuItem
                    value={periodDefinition.value}
                    key={`${periodDefinition.value}-menu-item`}
                  >{periodToDisplayPeriod(periodDefinition.value)}</MenuItem>)
                )}
              </Select>
            </FormControl>
          </TableCell>
        </TableRow>
        <TableRow key='min_voltage_threshold'>
          <TableCell>最低電圧</TableCell>
          <TableCell>{`${
            presentAlertSettings.min_voltage_threshold ? 
              `${presentAlertSettings.min_voltage_threshold} V` : '未設定'
          }`}</TableCell>
          <TableCell>
            <FormControl fullWidth>
              <NumberFormat
                value={newAlertSettings.min_voltage_threshold}
                suffix=" V"
                allowNegative={false}
                onValueChange={(values,) => {
                  handleMinVoltageChange(values.floatValue)
                }}
                inputProps={{
                  maxLength: 5
                }}
                customInput={TextField}
              />
            </FormControl>
          </TableCell>
        </TableRow>
        <TableRow key='missing_ratio_threshold'>
          <TableCell>欠損率上限</TableCell>
          <TableCell>{`${
            presentAlertSettings.missing_ratio_threshold ?
              `${presentAlertSettings.missing_ratio_threshold * 100} %` : '未設定'
          }`}</TableCell>
          <TableCell>
            <FormControl fullWidth>
              <NumberFormat
                value={newAlertSettings.missing_ratio_threshold ? (
                  Number((newAlertSettings.missing_ratio_threshold * 100).toFixed(2))
                ) : undefined}
                suffix=" %"
                allowNegative={false}
                onValueChange={(values,) => {
                  if (values.floatValue == null) {
                    handleMissingRatioChange(undefined)
                  } else {
                    handleMissingRatioChange(Number((values.floatValue / 100).toFixed(2)))
                  }
                }}
                inputProps={{
                  maxLength: 4
                }}
                customInput={TextField}
              />
            </FormControl>
          </TableCell>
        </TableRow>
        <TableRow key='error_ratio_threshold'>
          <TableCell>エラー率上限</TableCell>
          <TableCell>{`${
            presentAlertSettings.error_ratio_threshold ?
              `${presentAlertSettings.error_ratio_threshold * 100} %` : '未設定'
          }`}</TableCell>
          <TableCell>
            <FormControl fullWidth>
              <NumberFormat
                value={newAlertSettings.error_ratio_threshold ? (
                  Number((newAlertSettings.error_ratio_threshold * 100).toFixed(2))
                ) : undefined}
                suffix=" %"
                allowNegative={false}
                onValueChange={(values,) => {
                  if (values.floatValue == null) {
                    handleErrorRatioChange(undefined)
                  } else {
                    handleErrorRatioChange(Number((values.floatValue / 100).toFixed(2)))
                  }
                }}
                inputProps={{
                  maxLength: 4
                }}
                customInput={TextField}
              />
            </FormControl>
          </TableCell>
        </TableRow>
        <TableRow key='plateau_ratio_threshold'>
          <TableCell>不動検出率上限</TableCell>
          <TableCell>{`${
            presentAlertSettings.plateau_ratio_threshold ?
              `${presentAlertSettings.plateau_ratio_threshold * 100} %` : '未設定'
          }`}</TableCell>
          <TableCell>
            <FormControl fullWidth>
              <NumberFormat
                value={newAlertSettings.plateau_ratio_threshold ? (
                  Number((newAlertSettings.plateau_ratio_threshold * 100).toFixed(2))
                ) : undefined}
                suffix=" %"
                allowNegative={false}
                onValueChange={(values,) => {
                  if (values.floatValue == null) {
                    handlePlateauRatioChange(undefined)
                  } else {
                    handlePlateauRatioChange(Number((values.floatValue / 100).toFixed(2)))
                  }
                }}
                inputProps={{
                  maxLength: 4
                }}
                customInput={TextField}
              />
            </FormControl>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

export default AlertSettings;