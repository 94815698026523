import {Box} from '@mui/material'
import {QueryResultTotalData} from "../../types/types"
import BansCard from "../ui/BansCard";

interface TotalResultProps {
  total_count: number
  total: QueryResultTotalData
}

const TotalResult = (props: TotalResultProps) => {
  const {total_count, total} = props

  return (
    <Box
      sx={{ padding: 1 }}
      display="flex"
      justifyContent="space-evenly"
      alignItems="center"
    >
      <BansCard title="全対象センサ数" displayNumber={`${total_count}`} unit="個"/>
      <BansCard title="最低電圧以下のセンサ" displayNumber={`${total.under_min_voltage_threshold_count}`} unit="個"/>
      <BansCard title="欠損率上限以上のセンサ" displayNumber={`${total.over_missing_ratio_threshold_count}`} unit="個"/>
      <BansCard title="エラー率上限以上のセンサ" displayNumber={`${total.over_error_ratio_threshold_count}`} unit="個"/>
      <BansCard title="不動検出率上限以上のセンサ" displayNumber={`${total.over_plateau_ratio_threshold_count}`} unit="個"/>
    </Box>
  )
}

export default TotalResult