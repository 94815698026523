import React from "react";
import {AppBar, Box, Grid, Toolbar, Typography} from "@mui/material";
import logo from '../../images/logo-w.svg'

const Header = () => {
  return (
    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar>
        <Grid container justifyContent="start">
          <Typography variant="h6">GoNOW センサーアラートコンソール</Typography>
        </Grid>
        <Grid container justifyContent="end">
          <Box
            component="img"
            sx={{
              width: 150
            }}
            alt="ゼロスペック株式会社"
            src={logo}
          />
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Header;