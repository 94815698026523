import {
  errorMessage,
  isError,
  isNewAlertSettingsValid,
  isPresentAlertSettingsCopiable, isQuerying, isSaving,
  newAlertSettingsOfTargetGroup,
  presentAlertSettingsOfTargetGroup, queryResponse, targetGroup
} from "../../jotai/atom";
import {
  Box,
  Button,
} from "@mui/material";
import {useAtom} from "jotai";
import {callPostApi, testQuery} from "../../api/api";

const ControlButtons = () => {
  const [group, setGroup] = useAtom(targetGroup)
  const [presentAlertSettings] = useAtom(presentAlertSettingsOfTargetGroup)
  const [newAlertSettings, setNewAlertSettings] = useAtom(newAlertSettingsOfTargetGroup)
  const [isCopiable] = useAtom(isPresentAlertSettingsCopiable)
  const [isNewSettingsValid] = useAtom(isNewAlertSettingsValid)
  const [, setIsTestingConditions] = useAtom(isQuerying)
  const [, setTestQueryResponse] = useAtom(queryResponse)
  const [, setIsSavingAlertCondition] = useAtom(isSaving)
  const [, setIsErrorOccurred] = useAtom(isError)
  const [, setErrMessage] = useAtom(errorMessage)

  const copyPresentAlertSettings = () => {
    setNewAlertSettings({...presentAlertSettings})
  }

  const reloadSettings = () => {
    // 対象グループを設定し直すとアラート設定が再読み込みされる
    const groupNow = group
    setGroup(null)
    setGroup(groupNow)
  }

  if (presentAlertSettings == null) {
    return <></>
  }

  return (
    <Box
      sx={{ padding: 1 }}
      display="flex"
      justifyContent="space-evenly"
      alignItems="center"
    >
      <Button
        variant="outlined"
        onClick={copyPresentAlertSettings}
        disabled={!isCopiable}
      >
        現在値をコピー
      </Button>
      <Button
        variant="outlined"
        onClick={async () => {
          if (group != null) {
            setIsTestingConditions(true)
            const testQueryResponse = await testQuery(group, newAlertSettings)
            console.log(testQueryResponse)
            if (testQueryResponse.status !== 'DONE') {
              setIsErrorOccurred(true)
              setErrMessage(testQueryResponse.message)
            }
            setTestQueryResponse(testQueryResponse)
            setIsTestingConditions(false)
          }
        }}
        disabled={!isNewSettingsValid}
      >
        設定値で検証
      </Button>
      <Button
        variant="outlined"
        onClick={async () => {
          if (group != null) {
            setIsSavingAlertCondition(true)
            await callPostApi('/write_alert_condition',{
              groups: [{
                company_id: group.company_id,
                group_id: group.group_id,
              }],
              alert_settings: newAlertSettings,
            })
            reloadSettings()
            setIsSavingAlertCondition(false)
          }
        }}
        disabled={!isNewSettingsValid}
      >
        設定値を保存
      </Button>
    </Box>
  )
}

export default ControlButtons;