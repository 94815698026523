import {Group} from "../../types/types";
import {allGroupList, queryResponse, targetGroup} from "../../jotai/atom";
import {Autocomplete, TextField} from "@mui/material";
import {useAtom} from "jotai";

const GroupListDropdown = () => {
  const [groupList] = useAtom(allGroupList)
  const [,setGroup] = useAtom(targetGroup)
  const [,setTestResult] = useAtom(queryResponse)

  const groups = groupList.groups
  groups.sort((a: Group, b: Group) => (a.company_name > b.company_name) ? 1 : ((b.company_name > a.company_name) ? -1 : 0))

  return (
    <Autocomplete
      id="group-list"
      sx={{ padding: 1 }}
      onChange={(event: any, newValue: Group | null) => {
        setGroup(newValue)
        setTestResult(undefined) // グループを変更したら検証クエリの結果をリセット
      }}
      isOptionEqualToValue={(option, value) => option.group_id === value.group_id}
      groupBy={(option) => option.company_name}
      options={groups}
      getOptionLabel={(option) => option.group_name}
      renderInput={(params) => (
        <TextField
          {...params}
          label="グループを選択"
        />
      )}
    />
  )
}

export default GroupListDropdown;