import { API, Auth } from 'aws-amplify';
import {AlertSettingValues, Group, QueryResponse, StartQueryResponse} from "../types/types";

export const callPostApi = async (path: string, body?: object) => {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const init = {
    headers: {
      Authorization: token,
    },
    body
  };
  return API.post(
    'DefaultAPI', path, init
  )
}

export const testQuery = async (targetGroup: Group, newAlertSettings: AlertSettingValues) => {
  const querySettings = {
    company_id: targetGroup.company_id,
    group_id: targetGroup.group_id,
    alert_conditions: newAlertSettings,
  }
  const response = await callPostApi('/start_query', querySettings)
  if (!response.hasOwnProperty("task_id")) {
    return {
      status: 'ERROR',
      message: 'クエリが開始できませんでした',
    } as QueryResponse
  }
  const taskInfo = {...response} as StartQueryResponse

  const getQueryStatusOrResult = async (taskInfo: StartQueryResponse) => {
    const res = await callPostApi('/get_query_status_or_result', taskInfo)
    if (!res.hasOwnProperty("status")) {
      console.log(res)
      console.log('error at frontend')
      return {
        status: 'FATAL',
        message: `予期せぬエラーが発生しました。下記のタスクIDを添えて開発者にお問い合わせください。task_id=${taskInfo.task_id}`
      } as QueryResponse
    }
    return res as QueryResponse
  }

  const getQueryResult = async (taskInfo: StartQueryResponse) => {
    let res = await getQueryStatusOrResult(taskInfo)
    while (res.status === 'RUNNING') {
      await new Promise(resolve => setTimeout(resolve, 2000)) // 2秒スリープ
      console.log(res)
      res = await getQueryStatusOrResult(taskInfo)
    }
    console.log(res)
    return res
  }

  return getQueryResult(taskInfo)
}